@import 'fonts';
@import 'vars';
//@import 'libs'
@import 'utils/mixins';

* {
  margin: 0; }

html {
  line-height: 1;
  font-size: 14px;
  height: 100%; }

body {
  font-family: 'RubikRegular', sans-serif;
  color: #000;
  height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  input, textarea {
    //border: #666 1px solid
    outline: none;
    &:focus:required:invalid {
      border-color: red; }
    &:required:valid {
      border-color: green; } } }

h1,h2,h3,h4,h5,h6 {
  margin: 0; }

ul,li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

p {
  margin: 0; }

a {
  text-decoration: none;
  color: #000;
  &:focus {
    outline: 0; } }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

textarea {
  resize: none; }

section {
  position: relative;
  max-width: 1920px;
  margin: 0 auto; }

input,
select,
button {
  outline: none; }

*, *:before, *:after {
  box-sizing: border-box; }

.fixed {
  position: fixed;
  width: 100%; }
.tac {
  text-align: center; }
input[type="checkbox"]:focus {
  outline: 0; }
input[type="radio"], input[type="checkbox"] {
  margin: 0; }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

// Общие стили
@import 'common-styles';


// МОДУЛИ
// Стили шапки
@import 'modules/header/header';

// Стили футера
@import 'modules/footer/footer';

// Стили меню
@import 'modules/navigation/main-nav';

//Компоненты
@import "components/components";


// СТРАНИЦЫ
// Стили главной страницы
@import 'pages/index';
