@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		@if $asset-pipeline == true {
			src: font-url('#{$file-path}.eot');
			src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype'); }
		@else {
			src: url('#{$file-path}.eot');
			src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); } } }

@mixin absolute-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%); }

@mixin size($width, $height: $width) {
	width: $width;
	height: $height; }

@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem; }

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size); }
