.wrapper {
  max-width: 1920px;
  margin: 0 auto; }

.container__absolute {
  position: absolute;
  left: 50%;
  top: 47%;
  transform: translate(-50%,-50%);
  width: 100%;
  padding: 0 15px;
  z-index: 2;
  @media (min-width: 1200px) {
    max-width: 1200px; }
  @media (min-width: 992px) {
    max-width: 960px; }
  @media (min-width: 768px) {
    max-width: 720px; }
  @media (max-width: 768px) {
    position: relative;
    transform: none;
    left: auto;
    top: auto; }
  @media (min-width: 576px) {
    max-width: 540px;
    margin: 0 auto; } }

.section__header {
  position: relative;
  color: #fefefe;
  font-size: 36px;
  font-family: 'RubikMedium';
  @media (max-width: 768px) {
    font-size: 26px; }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    width: 70px;
    height: 4px;
    background-color: #ffb922;
    @media (max-width: 767px) {
      bottom: -20px; } }
  &--black {
    color: #111111; } }

.nav {
  &__top-wrapper {
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      flex-wrap: wrap; }
    @media (max-width: 410px) {
      justify-content: center; } }
  &__top-left {
    display: flex;
    align-items: center;
    font-size: 13px;
    @media (max-width: 991px) {
      width: 100%;
      justify-content: space-between; }
    @media (max-width: 767px) {
      font-size: 15px;
      width: auto;
      flex-direction: column;
      align-items: flex-start; }
    i {
      color: #ffb922;
      margin-right: 10px;
      font-size: 13px;
      @media (max-width: 767px) {
        font-size: 15px;
        margin: 3px 10px 3px 0; } }
    a, span {
      color: #636363;
      margin-right: 29px; }
    a:hover {
      text-decoration: underline; }
    .nav__top-email a {
      margin-right: 0; } }
  &__top-right {
    @media (max-width: 991px) {
      width: 100%;
      display: flex;
      justify-content: space-around; }
    @media (max-width: 767px) {
      width: auto;
      flex-direction: column;
      align-items: flex-end; }
    @media (max-width: 410px) {
      width: 100%;
      flex-direction: row;
      align-items: flex-start; } }
  &__top-btn {
    color: #000000;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 27px;
    transition: 0.2s ease;
    @media (max-width: 991px) {
      margin: 15px 0 0 0; }
    @media (max-width: 767px) {
      margin: 0;
      &:last-child {
        margin-top: 10px; } }
    @media (max-width: 410px) {
      margin-top: 10px; }
    &:hover {
      border-bottom: 1px solid #000; } }
  &__main {
    background-color: #0d0d0d;
    &-wrapper {
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  &__logo {
    font-family: 'RubikBold';
    color: #ffb922;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1.5px; }
  &__list {
    display: flex;
    align-items: center;
    height: 100%;
    @media (min-width: 992px) {
      display: flex !important; }
    @media (max-width: 991px) {
      position: fixed;
      display: none;
      height: 100vh;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #399898;
      background: linear-gradient(to bottom, #018EC3 0%,#A9D5E2 100%);
      z-index: 11;
      margin-top: 0;
      padding-top: calc(24px + 6vh);
      @media (max-height: 450px) {
        overflow: scroll; }
      li {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: calc(10px + 3vh); }
      a {
        font-size: calc(10px + 2.5vh);
        transition: 0.2s ease;
        width: 100%;
        text-align: center; } }
    li {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      margin: 0 18px;
      &:hover .nav__sublist {
        display: block; }
      @media (max-width: 991px) {
        height: auto; } }
    a {
      color: #f0f0f0;
      font-size: 13px;
      font-family: 'RubikMedium';
      text-transform: uppercase;
      transition: 0.2s ease;
      &:hover {
        color: #ffb922; }
      &.active {
        color: #ffb922; } }
    .nav__sublist {
      display: none;
      padding: 30px 42px 30px 22px;
      position: absolute;
      top: 67px;
      left: 50%;
      transform: translate(-50%);
      box-shadow: 0 6px 6px rgba(102, 102, 102, 0.45);
      background-color: #ffffff;
      border-radius: 5px;
      z-index: 4;
      @media (max-width: 991px) {
        position: static;
        transform: none; }
      li {
        margin: 17px 0 0 0;
        &:first-child {
          margin-top: 0; } }
      a {
        white-space: nowrap;
        color: #0d0d0d;
        &:hover {
          color: #ffb922; } } } }
  &__contact-btn {
    padding: 15px 38px 14px;
    color: #f0f0f0;
    font-size: 13px;
    font-family: 'RubikMedium';
    border: 2px solid #ffb922;
    text-transform: uppercase;
    transition: 0.2s ease;
    @media (max-width: 480px) {
      display: none; }
    &:hover {
      color: #ffb922;
      background-color: #323232; } }
  &__mobile-btn {
    display: none;
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 12;
    cursor: pointer;
    @media (max-width: 991px) {
      display: block; }
    span {
      position: absolute;
      display: block;
      left: 10%;
      width: 80%;
      height: 2px;
      background-color: #FFB922;
      transition: .2s; }
    &--top {
      top: 11px;
      width: 32px; }
    &--middle {
      top: 19px;
      width: 32px;
      opacity: 1; }
    &--bottom {
      top: 27px;
      width: 32px; }
    &:hover {
      .nav__mobile-btn--top {
        width: 80%; }
      .nav__mobile-btn--middle {
        width: 80%; } }
    &.active {
      .nav__mobile-btn--top {
        width: 32px;
        transform: rotate(45deg);
        top: 19px;
        transition: .2s;
        background-color: #fff; }
      .nav__mobile-btn--middle {
        opacity: 0;
        transition: .2s; }
      .nav__mobile-btn--bottom {
        width: 32px;
        transform: rotate(-45deg);
        top: 19px;
        transition: .2s;
        background-color: #fff; } } } }

.header {
  padding: 71px 0 81px;
  position: relative;
  background: url('../img/general/slide-main.jpg') center 75% no-repeat;
  background-size: cover;
  &__title {
    position: relative;
    color: #ffffff;
    font-size: 60px;
    font-family: 'RubikBold';
    text-transform: uppercase;
    margin-top: 32px;
    z-index: 2;
    @media (max-width: 991px) {
      font-size: 45px;
      margin-top: 15px; }
    @media (max-width: 480px) {
      font-size: 30px;
      margin-top: 8px; } }
  &__undertitle {
    position: relative;
    color: #ffb922;
    font-size: 20px;
    font-family: 'RubikMedium';
    text-transform: uppercase;
    z-index: 2; } }

.about {
  padding: 51px 0 58px;
  @media (max-width: 768px) {
    padding: 40px 0 10px; }
  h2 {
    margin: 33px 0 70px;
    @media (max-width: 1199px) {
      margin: 10px 0 70px; }
    @media (max-width: 768px) {
      margin: 10px 0 50px; }
    &::after {
      transform: none;
      left: 0; } }
  &__img {
    text-align: center;
    img {
      width: 100%;
      @media (max-width: 768px) {
        max-width: 412px;
        width: 100%; } } }
  p {
    color: #636363;
    line-height: 26px;
    @media (max-width: 991px) {
      line-height: 22px; } } }

.slider .slick-slide {
  @media (max-width: 768px) {
    display: flex;
    align-items: center; } }
.slider {
  width: 100%;
  &__item {
    position: relative;
    width: 100%;
    min-height: 400px;
    height: 100%;
    overflow: hidden;
    &:focus {
      outline: 0; }
    img {
      width: 100%;
      @media (max-width: 991px) {
        position: absolute;
        width: auto;
        height: 100%;
        left: 50%;
        transform: translateX(-50%); } } }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.55);
    z-index: 1; }
  &__header-wrap {
    max-width: 843px; }
  &__subtitle {
    color: #f0f0f0;
    font-size: 18px;
    line-height: 27px;
    margin-top: 32px;
    @media (max-width: 991px) {
      margin-top: 16px; }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px; } }
  &__bth-group {
    margin-top: 74px;
    @media (max-width: 991px) {
      margin-top: 26px; }
    a {
      display: inline-block;
      padding: 15px 38px 14px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'RubikMedium';
      text-transform: uppercase;
      border: 2px solid #ffb922;
      @media (max-width: 480px) {
        padding: 15px 19px 14px; } } }
  &__bth--project {
    background-color: #ffb922;
    margin-right: 20px; } }

.slick-arrow {
  position: absolute;
  border: none;
  cursor: pointer;
  background-color: transparent;
  z-index: 1; }

.slick-next,
.slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  background-color: rgba(81,81,81,0.5);
  border-radius: 50%;
  transition: all 0.2s ease;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px;
    background-color: #fff;
    transition: 0.2s ease; }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url('../img/general/slider-arrow.png') no-repeat;
    width: 22px;
    height: 40px;
    transition: 0.2s ease; } }

.slick-prev {
  left: 31px;
  &::after {
    left: 25px;
    height: 3px;
    width: 0; }
  &::before {
    transform: translate(-62%,-50%) rotate(-180deg); }
  &:hover {
    &::after {
      left: 15px;
      width: 37px; }
    &::before {
      transform: translate(-90%,-50%) rotate(-180deg); } } }

.slick-next {
  right: 31px;
  &::after {
    right: 25px;
    height: 3px;
    width: 0; }
  &::before {
    transform: translate(-30%,-50%); }
  &:hover {
    &::before {
      transform: translate(-10%,-50%); }
    &::after {
      right: 15px;
      width: 37px; } } }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.my-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 10px;
      height: 10px;
      padding: 4px;
      cursor: pointer;
      color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      outline: none;
      background: transparent;
      &:hover, &:focus {
        outline: none; }
      &:hover:before, &:focus:before {
        opacity: 1; }
      &:before {
        font-family: 'slick';
        font-size: 36px;
        line-height: 10px;
        position: absolute;
        top: 0px;
        left: -1px;
        width: 10px;
        height: 10px;
        content: '•';
        text-align: center;
        color: #fff; } }
    &.slick-active button {
      border: 1px solid #ffb922;
      &:before {
        opacity: 1;
        color: #ffb922; } } } }

.guide {
  width: 100%;
  padding: 32px 0;
  background-color: #ffb922;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: center; } }
  &__title {
    max-width: 750px;
    font-family: 'RubikBold';
    color: #ffffff;
    line-height: 30px;
    font-size: 30px;
    @media (max-width: 767px) {
      line-height: 27px;
      font-size: 24px; }
    @media (max-width: 575px) {
      width: 100%;
      text-align: center; } }
  &__btn {
    padding: 16.5px 19px;
    font-family: 'RubikBold';
    color: #0d0d0d;
    font-size: 13px;
    background-color: #ffffff;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1200px) {
      margin-left: 50px; }
    @media (max-width: 575px) {
      margin: 15px 0 0 0; } } }

.services {
  padding: 90px 0 48px;
  background: url('../img/general/services-bgr.jpg') center bottom no-repeat;
  background-size: cover;
  text-align: center;
  @media (max-width: 767px) {
    padding: 50px 0; }
  h2 {
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 60px; } }
  &__block {
    padding: 70px 50px 75px;
    background-color: rgba(13,13,13,0.16);
    color: #fff;
    @media (max-width: 1200px) {
      padding: 70px 43px 75px; }
    @media (max-width: 991px) {
      padding: 41px 14px 35px; }
    @media (max-width: 767px) {
      margin-bottom: 20px;
      padding: 20px 14px 20px; } }
  &__title {
    font-family: 'RubikMedium';
    font-size: 18px;
    margin-bottom: 15px; }
  &__desc {
    line-height: 26px;
    @media (max-width: 767px) {
      line-height: 19px; } }
  &__btn-group {
    margin-top: 65px;
    @media (max-width: 767px) {
      margin-top: 35px; }
    @media (max-width: 575px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
    a {
      display: inline-block;
      font-family: 'RubikMedium';
      font-size: 26px;
      color: #ffffff;
      background-color: #ffa422;
      border-radius: 5px;
      margin: 0 26px;
      &:first-child {
        padding: 10.5px 27px 12.5px; }
      &:last-child {
        padding: 10.5px 49px 12.5px;
        @media (max-width: 575px) {
          margin-top: 15px; } }
      @media (max-width: 767px) {
        margin: 0 16px; }
      @media (max-width: 575px) {
        width: 100%;
        margin: 0; } } } }

.help {
  padding: 78px 0 65px;
  @media (max-width: 767px) {
    padding: 50px 0; }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      flex-wrap: wrap; } }
  &__left {
    max-width: 770px; }
  .section__header {
    margin-bottom: 35px;
    @media (max-width: 991px) {
      margin-bottom: 20px; }
    &::after {
      content: none; } }
  p {
    color: #636363;
    line-height: 22px; }
  &__portfolio {
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin-top: 5px; }
    &-block {
      font-family: 'RubikMedium';
      @media (max-width: 991px) {
        width: 50%;
        margin-top: 10px; } }
    &-number {
      color: #ffb922;
      font-size: 50px;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        margin-bottom: 0; } }
    &-title {
      color: #4d4d4d;
      line-height: 24px; } }
  &__right {
    padding: 60px 45px 70px;
    max-width: 270px;
    font-family: 'RubikBold';
    box-shadow: 0 34px 54px rgba(0, 0, 0, 0.2);
    border: 15px solid #ffa422;
    color: #ffb922;
    border-radius: 5px;
    text-transform: uppercase;
    text-align: center;
    margin-left: 30px;
    @media (max-width: 767px) {
      margin: 25px auto 0;
      padding: 31px 45px 40px; }
    &-years {
      font-size: 120px; }
    &-subtitle {
      font-size: 18px;
      line-height: 30px; } } }

.instagram {
  padding: 65px 0;
  text-align: center;
  @media (max-width: 767px) {
    padding: 50px 0; }
  h2 {
    margin-bottom: 65px;
    @media (max-width: 767px) {
      margin-bottom: 50px; } }
  &__subtitle {
    max-width: 903px;
    color: #111111;
    font-size: 18px;
    line-height: 36px;
    margin: 0 auto;
    @media (max-width: 767px) {
      line-height: 27px; } } }

.ebook {
  padding: 75px 0 65px;
  @media (max-width: 767px) {
    padding: 50px 0; }
  &__img {
    img {
      width: 726px;
      margin-top: -25px;
      margin-left: -250px;
      @media (max-width: 1199px) {
        width: 100%;
        margin-top: 100px;
        margin-left: 0; }
      @media (max-width: 991px) {
        margin-top: 0; } } }
  h2 {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      line-height: 29px; }
    &::after {
      transform: none;
      left: 0; } }
  &__subtitle {
    color: #6c6a6a;
    font-size: 16px;
    margin-bottom: 23px;
    @media (max-width: 575px) {
      margin-bottom: 15px; } }
  &__form {
    input[type="text"],
    input[type="email"],
    input[type="number"] {
      padding: 15px 17px;
      display: block;
      width: 100%;
      color: #afafaf;
      font-size: 15px;
      border: 1px solid #d2d2d2;
      border-radius: none;
      appearance: none;
      margin-bottom: 22px;
      &::placeholder {
        color: #afafaf; }
      @media (max-width: 575px) {
        margin-bottom: 10px; } }
    input[type="submit"] {
      padding: 10.3px 60px;
      font-family: 'RubikMedium';
      color: #ffffff;
      font-size: 29px;
      background-color: #ffa422;
      border: none;
      border-radius: none;
      appearance: none;
      margin-top: 34px;
      cursor: pointer;
      @media (max-width: 575px) {
        margin-bottom: 15px; } } } }

.testimonials {
  padding: 75px 0 42px;
  background: url('../img/general/testimonials-bgr.jpg') center bottom no-repeat;
  background-size: cover;
  text-align: center;
  @media (max-width: 767px) {
    padding: 50px 0 40px; }
  .slider__item {
    min-height: auto; }
  h2 {
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin-bottom: 60px; } }
  .slider__overlay {
    background-color: rgba(0,0,0,0.45);
    z-index: 0; }
  &__carousel {
    &-text {
      padding: 0 15px;
      max-width: 1652px;
      color: #ffffff;
      font-size: 25px;
      line-height: 31px;
      margin: 0 auto;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px; } } }
  .my-dots {
    position: static;
    margin-top: 87px;
    @media (max-width: 768px) {
      margin-top: 23px; }
    li {
      button {
        &:before {
          color: transparent; } }
      &.slick-active button {
        &:before {
          opacity: 1;
          color: #ffb922; } } } }
  &__btn {
    display: inline-block;
    position: relative;
    padding: 15.8px 43px;
    color: #ffa422;
    font-size: 14px;
    border: 1px solid #ffa422;
    text-transform: uppercase;
    margin-top: 36px;
    z-index: 2; } }

.advantages {
  display: flex;
  @media (max-width: 1100px) {
    flex-wrap: wrap; }
  &__left {
    padding: 75px 15px;
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #1a1a1a;
    @media (max-width: 1100px) {
      width: 100%; }
    @media (max-width: 768px) {
      padding: 40px 10px; } }
  &__service {
    max-width: 650px;
    padding: 65px 10px 75px;
    border: 3px solid #edb838;
    text-align: center;
    color: #fff;
    @media (max-width: 768px) {
      padding: 25px 10px 35px; }
    &-title {
      font-size: 36px;
      font-family: 'RubikMedium';
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 15px; } }
    &-subtitle {
      line-height: 26px; } }
  &__right {
    padding: 80px 0;
    width: 50%;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    @media (max-width: 1100px) {
      width: 100%; }
    @media (max-width: 768px) {
      padding: 50px 0; }
    @media (max-width: 575px) {
      padding: 35px 0 40px; }
    &-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      width: 100%; } }
  &__block {
    text-align: center;
    img {
      margin-bottom: 53px;
      @media (max-width: 575px) {
        margin-bottom: 18px; } }
    &:nth-child(1) img {
      height: 151px;
      @media (max-width: 768px) {
        height: 100px; }
      @media (max-width: 575px) {
        height: 70px; } }
    &:nth-child(2) img {
      height: 140px;
      @media (max-width: 768px) {
        height: 100px; }
      @media (max-width: 575px) {
        height: 60px; } }
    &:nth-child(3) img {
      height: 128px;
      @media (max-width: 768px) {
        height: 100px; }
      @media (max-width: 575px) {
        height: 55px; } }
    &-title {
      color: #181818;
      font-size: 18px;
      font-family: 'RubikMedium';
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 16px; } } }
  &__rotate {
    transform: rotate(-90.5deg); } }

.form {
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap; }
  &__left {
    overflow: hidden;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%; }
    img {
      width: 100%;
      @media (max-width: 1576px) {
        width: 789px; }
      @media (max-width: 768px) {
        width: 100%; } } }
  h2 {
    margin-bottom: 83px;
    @media (max-width: 768px) {
      margin-bottom: 50px; } }
  &__right {
    padding: 50px 15px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #020614;
    color: #fff;
    @media (max-width: 768px) {
      width: 100%; }
    &-wrapper {
      max-width: 569px;
      width: 100%;
      text-align: center; } }
  &__main {
    input[type="text"],
    input[type="email"] {
      padding: 15px 20px;
      display: block;
      width: 100%;
      color: #c1c1c1;
      font-size: 14px;
      border-radius: 3px;
      border: none;
      appearance: none;
      margin-bottom: 29px;
      &::placeholder {
        color: #c1c1c1; }
      @media (max-width: 575px) {
        margin-bottom: 10px; } }
    input[type="submit"] {
      padding: 14.7px 49px;
      font-family: 'RubikBold';
      color: #ffffff;
      font-size: 14px;
      background-color: #dea430;
      border: none;
      border-radius: 3px;
      appearance: none;
      margin-top: 25px;
      cursor: pointer; } }
  &__holder {
    display: flex;
    justify-content: space-between;
    @media (max-width: 480px) {
      flex-wrap: wrap; }
    input[type="text"] {
      width: 48%;
      @media (max-width: 575px) {
        width: 49%; }
      @media (max-width: 480px) {
        width: 100%; } } } }

.footer {
  &__top {
    padding: 28px 0;
    display: flex;
    align-items: center;
    background-color: #ffb922;
    @media (max-width: 991px) {
      padding: 28px 0 14px; } }
  &__address {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin-bottom: 15px; }
    &-icon {
      position: relative;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #ffffff;
      margin-right: 29px;
      @media (max-width: 767px) {
        width: 70px;
        height: 70px; }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @media (max-width: 767px) {
          width: 37px; } } }
    &-text {
      display: flex;
      flex-direction: column; }
    span, a {
      color: #ffffff;
      font-size: 15px;
      margin: 5px 0; }
    a:hover {
      text-decoration: underline; } }
  &__middle {
    padding: 27px 0 84px;
    background-color: #000;
    @media (max-width: 768px) {
      padding: 27px 0 50px; } }
  &__title {
    position: relative;
    color: #ffb823;
    font-size: 20px;
    margin: 33px 0 65px;
    @media (max-width: 768px) {
      margin: 20px 0 33px; }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -21px;
      width: 41px;
      height: 2px;
      background-color: #ffb823;
      z-index: 1;
      @media (max-width: 768px) {
        bottom: -13px; } }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -21px;
      width: 100%;
      height: 1px;
      background-color: #fff;
      @media (max-width: 768px) {
        bottom: -13px; } } }
  &__info {
    padding: 0 10px; }
  &__logo {
    display: inline-block;
    font-family: 'RubikBold';
    color: #ffb922;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: -1.5px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      margin-bottom: 20px; } }
  p {
    color: #fefefe;
    font-size: 15px;
    line-height: 19px; }
  &__btn {
    display: inline-block;
    padding: 18.5px 32px;
    color: #ffb823;
    font-size: 16px;
    border: 2px solid #ffb823;
    text-transform: uppercase;
    margin: 37px 0 33px;
    transition: 0.2s ease;
    @media (max-width: 1210px) {
      padding: 18.5px 14px;
      font-size: 15px; }
    &:hover {
      color: #fff; } }
  &__mba-logo {
    @media (max-width: 575px) {
      display: block; } }
  &__social {
    a {
      position: relative;
      display: inline-block;
      width: 44px;
      height: 44px;
      border: 2px solid #ffb823;
      margin-right: 9px;
      &:hover i {
        color: #fff; } }
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #ffb823;
      font-size: 18px;
      transition: 0.2s ease; } }
  &__bot {
    padding: 35px 15px;
    color: #7f7e7e;
    font-size: 14px;
    text-align: center;
    span {
      @media (max-width: 992px) {
        line-height: 18px; } }
    a {
      color: #ffb922;
      &:hover {
        text-decoration: underline; } } }
  &__copyright {
    color: #000; }
  &__img {
    max-width: 250px;
    width: 100%; } }

.serv {
  padding: 83px 0 130px;
  @media (max-width: 768px) {
    padding: 50px 0; }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    @media (max-width: 480px) {
      flex-wrap: wrap; } }
  h2 {
    color: #181818;
    &::after {
      transform: none;
      left: 0; } }
  &__btn {
    padding: 16px 34px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'RubikMedium';
    background-color: #edb838;
    white-space: nowrap;
    text-transform: uppercase;
    @media (max-width: 480px) {
      width: 100%;
      margin-top: 40px;
      text-align: center; } }
  &__block {
    margin-top: 68px;
    @media (max-width: 768px) {
      margin-top: 30px; }
    @media (max-width: 575px) {
      max-width: 370px;
      margin: 30px auto 0; }
    img {
      width: 100%;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 20px; } }
    &-title {
      display: inline-block;
      color: #1e1e1e;
      font-size: 22px;
      font-family: 'RubikMedium';
      margin-bottom: 8px; }
    &-desc {
      color: #636363;
      line-height: 24px; } } }

.service {
  &__title {
    color: #000000;
    font-size: 18px;
    font-family: 'RubikMedium';
    @media (max-width: 767px) {
      text-align: center; } }
  .serv__head {
    margin-bottom: 75px;
    @media (max-width: 767px) {
      margin-bottom: 43px; } }
  &__list {
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 30px; }
    li {
      margin-top: 30px;
      @media (max-width: 767px) {
        width: 33%;
        margin-top: 20px;
        text-align: center; } }
    a {
      color: #636363;
      font-size: 14px;
      transition: 0.1s ease;
      &:hover,
      &.active {
        color: #edb838; } } }
  &__img {
    img {
      width: 100%; } }
  &__desc {
    margin: 38px 0 75px;
    @media (max-width: 767px) {
      margin: 30px 0 10px; }
    p {
      color: #636363;
      font-size: 15px;
      line-height: 20px; } }
  &__info {
    &-title {
      color: #181818;
      font-size: 18px;
      font-family: 'RubikMedium';
      margin-bottom: 15px;
      @media (max-width: 767px) {
        margin-top: 20px; } }
    &-desc {
      color: #636363;
      line-height: 26px;
      @media (max-width: 991px) {
        line-height: 20px; } } } }

.faq {
  .form__right-wrapper {
    max-width: 498px;
    text-align: left;
    color: #181818; }
  .form__left {
    @media (max-width: 1000px) {
      width: 30%; }
    @media (max-width: 767px) {
      width: 100%; } }
  .form__right {
    padding: 50px 75px;
    justify-content: flex-start;
    background-color: #e4e4e4;
    @media (max-width: 1172px) {
      padding: 50px 30px; }
    @media (max-width: 1000px) {
      width: 70%; }
    @media (max-width: 767px) {
      width: 100%; }
    @media (max-width: 575px) {
      padding: 30px 15px; }
    h2 {
      margin-bottom: 67px;
      color: #181818;
      @media (max-width: 575px) {
        margin-bottom: 45px; }
      &::after {
        transform: none;
        left: 0; } }
    ul {
      margin-top: 25px; }
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 3px;
        background: url('../img/general/check.png') 0 0 no-repeat;
        width: 19px;
        height: 15px; } } } }

.accordion {
  margin-top: 125px;
  @media (max-width: 991px) {
    margin-top: 70px; }
  @media (max-width: 767px) {
    margin-top: 40px; }
  li {
    border-bottom: 1px solid rgba(214,214,214,0.5);
    position: relative; }
  p {
    display: none;
    padding: 0 14px 37px 69px;
    color: #636363;
    line-height: 24px;
    @media (max-width: 767px) {
      padding: 0 14px 14px 14px; } }
  a {
    position: relative;
    padding: 51px 10px 51px 69px;
    width: 100%;
    display: block;
    color: #ffb922;
    font-size: 16px;
    font-family: 'RubikMedium';
    user-select: none;
    cursor: pointer;
    @media (max-width: 767px) {
      padding: 20px 10px 20px 55px; }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #ffb922;
      background-color: #ffffff; }
    &::before {
      content: "+";
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      font-size: 20px;
      transition: all 0.1s ease-in-out;
      z-index: 1; }
    &.active {
      &::after {
        background-color: #ffb922; }
      &::before {
        content: "-";
        left: 16px;
        color: #181818;
        transition: all 0.1s ease-in-out; } } } }
